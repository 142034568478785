// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-bebeletes-tsx": () => import("./../../../src/pages/bebeletes.tsx" /* webpackChunkName: "component---src-pages-bebeletes-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-order-tsx": () => import("./../../../src/pages/order.tsx" /* webpackChunkName: "component---src-pages-order-tsx" */),
  "component---src-pages-ribbons-tsx": () => import("./../../../src/pages/ribbons.tsx" /* webpackChunkName: "component---src-pages-ribbons-tsx" */),
  "component---src-templates-bebelete-tsx": () => import("./../../../src/templates/Bebelete.tsx" /* webpackChunkName: "component---src-templates-bebelete-tsx" */),
  "component---src-templates-ribbon-tsx": () => import("./../../../src/templates/Ribbon.tsx" /* webpackChunkName: "component---src-templates-ribbon-tsx" */)
}

