import React from "react";

export default function Footer() {
  return (
    <footer>
      <p className="center">
        &copy; Bebela's Ribbons {new Date().getFullYear()}
      </p>
    </footer>
  );
}
