import React, { ReactElement, useState } from "react";

interface IOrderProvider {
  children: ReactElement;
}

const OrderContext = React.createContext([]);

export function OrderProvider({ children }: IOrderProvider) {
  const [order, setOrder] = useState([]);
  return (
    <OrderContext.Provider value={[order, setOrder]}>
      {children}
    </OrderContext.Provider>
  );
}

export default OrderContext;
